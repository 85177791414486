define("discourse/plugins/knime-hub-plugin/discourse/initializers/knime-hub-search", ["exports", "discourse/lib/utilities", "discourse/lib/autocomplete", "discourse/lib/debounce", "discourse/lib/plugin-api", "discourse-common/lib/raw-templates", "rsvp"], function (_exports, _utilities, _autocomplete, _debounce, _pluginApi, _rawTemplates, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global $ */

  const TRIGGER_KEY = '/';
  const SEARCH_LIMIT = 6;
  const DEBOUNCE = 300;
  const CANCEL_TIMEOUT = 5000;
  const MAX_CACHE_TIME = 30000;
  const MIN_TERM_LENGTH = 3;
  let oldSearch = null;
  let cacheTime;
  let cache = {};
  const updateCache = (term, results) => {
    cache[term] = results;
    cacheTime = new Date();
    return results;
  };
  const getTypeCharacter = type => {
    const map = {
      Extension: 'e',
      Node: 'n',
      Workflow: 'w',
      Component: 'c'
    };
    return map[type] || 'w';
  };
  const charToSearchType = c => {
    const map = {
      e: 'Extension',
      n: 'Node',
      w: 'Workflow',
      c: 'Component'
    };
    return map[c] || 'All';
  };
  const triggerRule = textarea => {
    const cp = (0, _utilities.caretPosition)(textarea);
    let triggerPos = textarea.value.lastIndexOf(TRIGGER_KEY, cp);
    // If the user has given a specific type, we have to go back 2 characters further
    if (triggerPos >= 2 && textarea.value.substring(triggerPos - 2, triggerPos).match(/^\/[enwc]$/g)) {
      triggerPos -= 2;
    }
    if (triggerPos > 0 && !textarea.value.charAt(triggerPos - 1).match(/^\s$/)) {
      return false;
    }
    return !(0, _utilities.inCodeBlock)(textarea.value, cp);
  };
  const getDescription = item => {
    let descr = `${item.itemType}`;
    if (item.itemType === 'Workflow' || item.itemType === 'Component') {
      descr = `${item.itemType} by ${item.owner}`;
    }
    return `${item.title} (${descr})`;
  };
  const searchHub = (endpoint, term, type) => new _rsvp.Promise(resolve => {
    const clearPromise = setTimeout(() => {
      resolve(_autocomplete.CANCELLED_STATUS);
    }, CANCEL_TIMEOUT);
    const debouncedSearch = (0, _debounce.default)((query, resultFunc) => {
      oldSearch = $.ajax(endpoint, {
        type: 'GET',
        cache: true,
        data: {
          limit: SEARCH_LIMIT,
          query,
          type,
          scoreLimit: 0
        }
      });
      let returnVal = _autocomplete.CANCELLED_STATUS;
      oldSearch.then(r => {
        if (!r.results.length && term.length < MIN_TERM_LENGTH) {
          returnVal = [{
            empty: true
          }];
        } else {
          // Add properties for handlebars rendering
          for (let res of r.results) {
            res.iconName = `knime-${res.itemType.toLowerCase()}`;
            res.description = getDescription(res);
          }
          returnVal = r.results;
        }
      }).always(() => {
        resultFunc(returnVal);
      });
    }, DEBOUNCE);
    debouncedSearch(term, result => {
      clearTimeout(clearPromise);
      resolve(updateCache(term, result));
    });
  });
  const search = (endpoint, term) => {
    // If a search is running, abort it
    if (oldSearch) {
      oldSearch.abort();
      oldSearch = null;
    }
    // Check cache
    if (new Date() - cacheTime > MAX_CACHE_TIME) {
      cache = {};
    }
    const cached = cache[term];
    if (cached) {
      return cached;
    }

    // See if we have to extract a query type (e.g. /e/ for extensions)
    let type = 'All';
    if (term.match(/^[enwc]\/.*$/g)) {
      type = charToSearchType(term.substring(0, 1));
      term = term.substring(2);
    }
    // + may be handled differently on the server-side in the future
    term = term.replace('+', ' ');
    return searchHub(endpoint, term, type);
  };
  var _default = _exports.default = {
    name: 'knime-hub',
    initialize() {
      (0, _pluginApi.withPluginApi)('1.1.0', api => {
        // Add a button to the toolbar
        api.onToolbarCreate(toolbar => {
          toolbar.addButton({
            id: 'hub_search_btn',
            group: 'extras',
            icon: 'search',
            title: 'knime_hub_search.title',
            perform: e => {
              const element = toolbar.context.element.querySelector('.d-editor-input');
              const $element = $(element);
              // When something is selected, we use that as query
              const start = element.selectionStart;
              const end = element.selectionEnd;
              const selected = $element.val().substring(start, end);

              // Step 1: Trigger key (with leading space, if necessary)
              let txt = TRIGGER_KEY;
              if (start > 0 && !$element.val().substring(start - 1, start).match(/\s/)) {
                txt = ` ${txt}`;
              }
              e.addText(txt);
              element.dispatchEvent(new Event("keyup")); // Step 2: Key down for last key in query

              e.addText(txt + selected.replace(/\s+/g, '+'));
              element.dispatchEvent(new Event("keydown")); // Step 3: Key up for last key in query

              element.dispatchEvent(new Event("keyup"));
            }
          });
        });

        // We modify the editor component to add autocompletion for hub search
        api.modifyClass('component:d-editor', {
          // When the editor is inserted, we add our autocompletion logic
          didInsertElement() {
            this._super(...arguments);
            const server = this.siteSettings.knime_hub_host;
            const searchEndpoint = `${server}/search`;
            const $editorInput = $(this.element.querySelector('.d-editor-input'));
            // We save the last inserted text so we can remove the trigger char on completion
            let insertion = null;
            // Initialize autocompletion
            $editorInput.autocomplete({
              template: (0, _rawTemplates.findRawTemplate)('hub-autocomplete'),
              key: TRIGGER_KEY,
              afterComplete: text => {
                // Calculate position of trigger key
                let cpos = (0, _utilities.caretPosition)($editorInput.get(0));
                let triggerPos = cpos - insertion.length - 1;
                let key = text.substring(triggerPos - 1, triggerPos);
                if (key === TRIGGER_KEY) {
                  let corrected = text.substring(0, triggerPos - 1) + text.substring(triggerPos);
                  $editorInput.val(corrected);
                }
                insertion = null;
                this._focusTextArea();
              },
              transformComplete: obj => {
                // Create in-text link with title for the clicked item
                insertion = `[${obj.title}](https://kni.me/${getTypeCharacter(obj.itemType)}/${obj.id.replace('*', '')})`;
                return insertion;
              },
              dataSource: term => term.match(/\s/) ? null : search(searchEndpoint, term),
              triggerRule
            });
          }
        });
      });
    }
  };
});